<template>
  <v-row class="px-0">
    <v-col
      v-if="title"
      cols="12"
      :class="['text text-5  pb-0 pt-0', bold ? 'normal-label' : '']"
    >
      {{ title
      }}<span
        class="pl-1"
        style="color: #a5a5a5; font-weight: 400; font-size: 12px"
        >{{ optional_field }}</span
      >
    </v-col>
    <v-col
      cols="12"
      class="pt-1 pb-0 custom-red-color"
      v-if="!title_alone && !isDate"
    >
      <v-text-field
        v-if="$route.path == '/dashboard/tariff' && this.edit != true"
        :placeholder="placeholder"
        :suffix="suffix"
        :id="id"
        :rules="
          this.required == true ? this.inputRules : []
        "
        v-model="field_value"
        :name="id"
        type="number"
        background-color="#ffffff"
        class="edge-field"
        dense
        outlined
        v-on:keyup="callMethod"
        v-on:change="callMethod"
      >
        <template v-slot:append>
          <div
            :class="{
              'warning-box-order': checkError(),
            }"
          ></div>
        </template>
      </v-text-field>
      <v-text-field
        v-if="$route.path == '/dashboard/tariff' && this.edit == true"
        :placeholder="placeholder"
        :suffix="suffix"
        v-model="edit_value"
        type="number"
        background-color="#ffffff"
        :class="[edit_value ? 'filled-input' : '']"
        :rules="
          this.required == true ? this.inputRules : []
        "
        dense
        outlined
        v-on:keyup="callMethod"
        v-on:change="callMethod"
      >
      </v-text-field>
      <v-text-field
        v-if="$route.path == '/dashboard/support'"
        :placeholder="placeholder"
        :suffix="suffix"
        :type="text_field_type"
        v-model="faq_value"
        :rules="
          this.required == true ? this.inputRules : []
        "
        height="44px"
        background-color="#ffffff"
        dense
        outlined
        :class="[faq_value ? 'filled-input' : '']"
        v-on:keyup="callMethod"
        v-on:change="callMethod"
      >
      </v-text-field>
      <v-text-field
        v-if="$route.path == '/dashboard/manage-account'"
        :placeholder="placeholder"
        :suffix="suffix"
        :type="typeNumber ? 'number' : text_field_type"
        v-model="profile_value"
        height="44px"
        background-color="#ffffff"
        dense
        outlined
        :class="[profile_value ? 'filled-input' : '']"
        v-on:keyup="callMethod"
        v-on:change="callMethod"
        :rules="
          this.required == true ? this.inputRules : []
        "
        validate-on-blur
      >
      </v-text-field>
    </v-col>
    <v-col cols="12" class="pb-0 pt-1" v-if="isDate">
      <v-menu
        ref="menu1"
        v-if="!edit"
        v-model="menu1"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="dataValue"
            v-bind="attrs"
            readonly
            :rules="[(v) => !!v || 'Por favor preencha este campo']"
            :placeholder="placeholder"
            background-color="#ffffff"
            dense
            outlined
            v-on="on"
          >
            <template v-slot:append v-if="icon">
              <span v-on="on" style="height: 100%">
                <v-icon color="#a7a7a7" class="send-email-icon">{{
                  icon
                }}</v-icon>
              </span>
            </template>
          </v-text-field>
        </template>
        <v-date-picker
          v-model="field_value"
          no-title
          locale="pt-pt"
          :value-format="'DD-MM-YYYY'"
          @change="changeFormat"
          @input="menu1 = false"
        ></v-date-picker>
      </v-menu>

      <v-menu
        ref="menu2"
        v-else
        v-model="menu1"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="dataValueEdit"
            v-bind="attrs"
            readonly
            :rules="[(v) => !!v || 'Por favor preencha este campo']"
            background-color="#ffffff"
            dense
            outlined
            v-on="on"
          >
            <template v-slot:append v-if="icon">
              <span v-on="on" style="height: 100%">
                <v-icon color="#a7a7a7" class="send-email-icon">{{
                  icon
                }}</v-icon>
              </span>
            </template>
          </v-text-field>
        </template>
        <v-date-picker
          v-model="edit_value"
          no-title
          locale="pt-pt"
          @input="menu1 = false"
        ></v-date-picker>
      </v-menu>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import mixins from "vue-typed-mixins";
import { General } from "@/mixins/general";
import moment from "moment";

export default mixins(General).extend({
  name: "FormText",
  data() {
    return {
      formValues: {} as { [key: string]: any },
      menu1: false,
      warning: false,
      date: new Date().toISOString().substring(0, 10),
    };
  },

  computed: {
    inputRules() {
      if (this.required) {
        const regex = /^[0-9,.]+$/;
        return [
          (v:any) => {
            if (!v) {
              this.setWarning(true);
              return "Por favor preencha este campo";
            } else if (!regex.test(v)) {
              return "Por favor inserir um valor válido";
            } else {
              this.setWarning(false);
              return true;
            }
          },
        ];
      } else {
        // Make sure to return an empty array if no validation is needed
        return [];
      }
    },
    dataValue: {
      get(): any {
        if (this.field_value) {
          const data = this.field_value.split("-");
          return data[2] + "-" + data[1] + "-" + data[0];
        } else return "";
      },
    },
    dataValueEdit: {
      get(): any {
        if (this.edit_value) {
          const data = this.edit_value.split("-");
          return data[2] + "-" + data[1] + "-" + data[0];
        } else return "";
      },
    },
  },

  methods: {
    setWarning(val: any) {
      this.warning = val;
    },

    checkError() {
      if (this.required && this.error) return true;
      else return false;
    },

    changeFormat(e: any) {
      const date = new Date(e);
      const date_return = moment(date).format("DD-MM-YYYY");
      return date_return;
    },
  },

  watch: {
    warning(val: any) {
      this.$emit("warning", val);
    },
  },

  props: [
    "optional_field",
    "title",
    "placeholder",
    "error",
    "suffix",
    "edit",
    "bold",
    "required",
    "title_alone",
    "icon",
    "isDate",
    "field_key",
    "index",
    "rules",
    "typeNumber",
    "id",


  ],
});
</script>

<style lang="scss">
.v-messages {
  display: flex !important;
  align-items: center;
}

.v-text-field__details {
  padding-top: 3px;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.v-text-field__details .error--text::before {
  background-image: url("../../assets/icons/warning-121.svg");
  content: "";
  display: inline-block;
  width: 18px;
  height: 18px;
  background-size: contain;
  background-repeat: no-repeat;
  margin-left: 2px;
  margin-right: 5px;
}
.v-text-field__details .error--text {
  background-color: #feefef;
  border-radius: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 9px;
  margin-bottom: 2px;
}

.warning-box-border {
  border-color: red;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Hide up/down arrows in Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>